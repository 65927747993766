export const getStandardSignature = (event) => {
    let name = `
Feestelijke groet,

Eline
Consultant & Relatiebeheer

Aanwezig: maandag t/m donderdag
    `;
    if(event.type == "aanpakker") {
        name = "Feestelijke groet,\n\nTjitte\nConsultant\n\nAanwezig: maandag t/m donderdag";
    }
    return name.trim();
}
